<template>
    <div class="CutMoney">
        <ReportView
            ref="refsReportView"
            :options="options"
            @changeDeep = changeDeep
        >
            <template #sort>
                <el-dropdown style="margin-top: 7px;margin-right: 10px">
                    <el-button class="el-dropdown-link">{{lang['el-button.sort']}}</el-button>
                    <template #dropdown>
                        <el-dropdown-menu v-if="version === 1">
                            <el-dropdown-item @click="SortData('cut')">{{lang['TableColumn.label.cut']}}</el-dropdown-item>
                            <el-dropdown-item @click="SortData('money')">{{lang['TableColumn.label.money-1']}}</el-dropdown-item>
                            <el-dropdown-item @click="SortData('all')">{{lang['TableColumn.label.all-1']}}</el-dropdown-item>
                        </el-dropdown-menu>
                        <el-dropdown-menu v-else>
                            <el-dropdown-item @click="SortData('cut')">{{lang['TableColumn.label.cut']}}</el-dropdown-item>
                            <el-dropdown-item @click="SortData('UserMoneyDay')">{{lang['TableColumn.label.UserMoneyDay']}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
        </ReportView>
    </div>

</template>

<script setup>
import ReportView from "../../components/Report/ReportView";
import {onMounted, ref} from "vue";
import axiosConfig from "../../api/axiosConfig";
import cookies from "../../utils/cookies";
import common from "../../utils/common";
import {SortReportArr} from "../../utils/ReportFormsCommon";
const version = Number(cookies.getCookie('version'));
import langLib from '@/lang/index';
const lang = langLib.getLang('ReportForms','CutMoney')
const options = ref({
    TableColumn:[
        {label:lang['TableColumn.label.id'],prop:'id',width:90},
        {label:lang['TableColumn.label.agent'],prop:'agent',},
        {label:lang['TableColumn.label.username'],prop:'username',},
        {label:lang['TableColumn.label.cut'],prop:'cut',},
        {label:version === 1
                ?
                lang['TableColumn.label.money-1']
                : lang['TableColumn.label.money-2'],prop:'money',}
        ,
    ],
})
const refsReportView = ref();
const IsDeep = ref(false);
const ReportArrAll = ref([]);
const TempArr = ref([]);
const GetReport = () => {
    axiosConfig.post('ReportForms.User/GetCutAndMoney',{deep:IsDeep.value ? 1 : 0}).then(res=>{
        if (!common.checkRes(res,true)){return}
        let temp = res.data.data;
        if (version === 1){
            TempArr.value = temp;
        }else {
            for (let tempKey in temp) {
                let obj = temp[tempKey];
                //月费版未切割用户需要显示"未切割"
                if (obj?.CutStatus === 2){
                    obj.money = '未切割';
                    obj.UserMoneyDay = 366;
                }else{
                    obj.UserMoneyDay = CalcUserTimeDay(obj.money);
                }
                TempArr.value.push(obj);
            }
        }
        ReportArrAll.value = TempArr.value;
        refsReportView.value.SetTableDataArr(TempArr.value)
    })
}
const toDay = new Date();
const CalcUserTimeDay = (inputTime) => {
    // 将输入时间字符串转换为Date对象
    let inputDate = new Date(inputTime);
    // 计算相差的毫秒数
    let timeDiff = inputDate.getTime() - toDay.getTime();
    // 将毫秒数转换为天数
    let daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
}
onMounted(()=>{
    GetReport();
    if(version === 1){
        options.value.TableColumn.push({label:lang['TableColumn.label.all-1'],prop:'all',})
    }else {
        options.value.TableColumn.push({label:lang['TableColumn.label.UserMoneyDay'],prop:'UserMoneyDay',})
    }
})

const changeDeep = (bool) => {
    IsDeep.value = bool;
    GetReport();
}

const SortData = (field) => {
    refsReportView.value.SetTableDataArr(SortReportArr(ReportArrAll,field))
}
</script>

<style scoped>

</style>